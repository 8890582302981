<template>
  <v-container>
    <v-row>
      <v-col md="12">
        <v-toolbar :dark="$store.state.isDarkMode">
          <template>
            <v-tabs v-model="tab" align-with-title color="#757575">
              <v-tabs-slider></v-tabs-slider>
              <v-col md="3"></v-col>
              <v-col md="3"  v-if="returnAbility('delivery_note:orders')" >
                <v-tab :href="'#' + '1'">{{
                  $t("Orders")
                }}</v-tab>
              </v-col>
              <v-col md="3" v-if="returnAbility('delivery_note:index')">
                <v-tab :href="'#' + '2'">
                  {{ $t("Delivered Materials") }}
                </v-tab>
              </v-col>
            </v-tabs>
          </template>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-tabs-items :dark="$store.state.isDarkMode" v-model="tab">
      <v-tab-item value="1">
        <warehouse v-if="tab == '1'" />
      </v-tab-item>
      <v-tab-item value="2">
        <deliveriedOrder v-if="tab == '2'" />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import deliveriedOrder from './deliveriedOrder.vue'
import warehouse from "./warehouse.vue";
export default {
  components: {
    deliveriedOrder,
    warehouse,
  },
  data() {
    return {
      tab: false,
    };
  },
};
</script>

<style>
.registration-record__img-wrapper {
  width: 150px;
  height: auto;
  cursor: pointer;
}

.registration-record__img {
  width: 100%;
}
</style>
